<template>
    
  <v-card>
    <v-card-title>Map</v-card-title>
  </v-card>

</template>

<script>
// -- import something here --

export default {

  data: () => ({
    
  }),

  mounted() {
    
  },

  methods: {
    
  },

};
</script>

<style lang="scss">
@import '~scss/main';


</style>


